import http from "@/utils/http";

export async function signup(data) {
  const req = (await http.post('/auth/signup',data));

  return req.data;
}

export async function verify_email(data) {
  const req = (await http.post('/auth/verify-email',data));

  return req.data;
}

export async function resend_otp(data) {
  const req = (await http.get(`/auth/resend-email-verification/${data}`));

  return req.data;
}

export async function login(data) {
  const req = (await http.post('/auth/login',data));

  return req.data;
}

export async function logout() {
  const req =(await http.get('/auth/logout'));

  return req.data;
}

export async function password_reset_link(data) {
  const req = (await http.post('/auth/reset-password',data));
  return req.data;   
}

export async function reset_password(data) {
  const req = (await http.post(`/auth/reset-password/${data.email}/${data.token}`,{newPassword:data.newPassword}));
  return req.data;   
}

export async function signup_via_invite(data) {
  const req = (await http.post(`/auth/signup-via-invite/${data.email}/${data.token}`,{first_name:data.first_name, last_name:data.last_name,phone_number:data.phone_number, newPassword:data.password}));
  return req.data;   
}

export async function user_details() {
  const req = (await http.get(`/user`));

  return req.data;
}