<template>
        <b-form-group>
            <app-label >{{label}}</app-label>
            <b-form-select
                :value="value"
                :options="options"
                placeholder="Role"
                :state="errors.length?false:null"
                @input="handleSelect($event)"
                @change="()=>$emit('onChange')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
</template>
<script>
import {BFormGroup, BFormSelect} from 'bootstrap-vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
export default {
    components: {
        BFormGroup,
        BFormSelect,
        AppLabel,
    },
    props: {
        label:[String],
        options:[Array],
        value:[String,Number],
        placeholder:[String],
        errors:{
            type:[Array],
            default:()=>[]
        }
    },
    methods:{
        handleSelect(value){
            console.log('selected value:', value);
            this.$emit('input', value)
        }
    }

}
</script>
<style>

</style>